<template>
  <div style="width: 100%">

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Фільтри
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Менеджер</label>
            <v-select
                v-if="managers"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="filterProfileId"
                :options="managers"
                class="w-100"
                label="fullName"
                :reduce="val => val.profileId"
                placeholder="Оберіть менеджера"
                @input="onFilterUpdate"
            />
            <b-skeleton v-else type="input" />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Шаблони знижок</label>
            <v-select
                v-if="mergedTemplates"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="filterTemplateId"
                :options="mergedTemplates"
                class="w-100"
                label="title"
                :reduce="val => val.id"
                placeholder="Оберіть шаблон"
                @input="onFilterUpdate"
            />
            <b-skeleton v-else type="input" />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Виконані / Невіконані</label>
            <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="filterNonExecuted"
                :options="nonExecutedSelect.options"
                class="w-100"
                label="title"
                placeholder="Оберіть статус"
                :reduce="val => val.value"
                @input="onFilterUpdate"
            />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Дата запланованого шаблону</label>
            <b-form-datepicker
                v-model="filterDatesScheduled"
                locale="UA"
                @input="onFilterUpdate"
                placeholder="Оберіть дату"
            />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Статус</label>
            <v-select
                v-if="tasksStatuses"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="filterStatusId"
                :options="tasksStatuses"
                class="w-100"
                label="translated"
                :reduce="val => val.value"
                placeholder="Оберіть статус"
                @input="onFilterUpdate"
            />
            <b-skeleton v-else type="input" />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Дата призначення</label>
            <b-form-datepicker
                v-model="filterDateFrom"
                locale="UA"
                @input="onFilterDateUpdate"
                placeholder="Оберіть дату призначення"
            />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 my-2 d-flex align-items-end"
          >
            <b-button
                @click.prevent="resetFilter"
                variant="primary"
            >
              Скинути фільтр
            </b-button>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker, BButton, BSkeleton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { onBeforeMount, reactive, toRefs, ref } from "@vue/composition-api";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import { useTasksStatuses } from "@/composition/formOptions/useTasksStatuses";
import { useDiscountChangesFetch } from "@/composition/discounts/useDiscountChangesFetch";
import { useManagers } from "@/composition/businessUsers/useManagers";

const initialFilterObject = {
  filterProfileId: null,
  filterTemplateId: null,
  filterNonExecuted: null,
  filterDatesScheduled: null,
  filterStatusId: null,
  filterDateFrom: null,
  filterDateTo: null,
  page: 1,
}
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BButton,
    BSkeleton,
    vSelect,
  },
  setup() {
    const { fetchDiscountTemplates, fetchAllDiscounts, mergedTemplates } = useDiscountTemplatesFetch();
    const { tasksStatuses, fetchTasksStatuses } = useTasksStatuses();
    const { fetchDiscountChanges } = useDiscountChangesFetch();
    const { fetchManagers, managers } = useManagers();

    const filterOptions = reactive({...initialFilterObject});

    const nonExecutedSelect = ref({
      value: "",
      options: [
        { title: 'Виконані', value: false },
        { title: 'Невиконані', value: true },
      ]
    });

    const resetFilter = () => {
      Object.assign(filterOptions, initialFilterObject);
      fetchDiscountChanges(filterOptions)
    };

    const onFilterUpdate = async () => {
      await fetchDiscountChanges(filterOptions);
    };

    const onFilterDateUpdate = async () => {
      filterOptions.filterDateTo = filterOptions.filterDateFrom;
      await fetchDiscountChanges(filterOptions);
    };

    onBeforeMount(() => {
      fetchDiscountTemplates();
      fetchTasksStatuses();
      fetchManagers();
      fetchAllDiscounts();
    });

    return {
      tasksStatuses,
      managers,
      mergedTemplates,

      nonExecutedSelect,

      onFilterUpdate,
      resetFilter,
      onFilterDateUpdate,

      ...toRefs(filterOptions),
    }
  },
};
</script>
