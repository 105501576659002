import { reactive, toRefs } from '@vue/composition-api'
import FormOptionsService from "@/services/formOptions/formOptions.service";

const accountStatusesTranslations = {
    new: "Нове",
    processing: "В обробці",
    error: "Помилка",
    in_queue: "В черзі",
    rejected: "Відхилене",
    ignored: "Проігнороване",
    completed: "Завершене",
};
export function useTasksStatuses() {
    const data = reactive({
        tasksStatuses: null,
    })

    const fetchTasksStatuses =  async () => {
        const response = await FormOptionsService.tasksStatuses();
        response?.data?.items?.forEach(el => {
            el.translated = accountStatusesTranslations[el.title]
        })
        data.tasksStatuses = response?.data.items;
    };

    const getTasksStatusesTitle = (id) => {
        const title = data.tasksStatuses?.find(el => el.value === id)?.title;
        return accountStatusesTranslations[title];
    }

    return { fetchTasksStatuses, ...toRefs(data), getTasksStatusesTitle };

}