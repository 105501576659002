<template>
  <div>
    <discountsProcessingFilter />
    <discountsChangesList />
  </div>
</template>

<script>

import discountsProcessingFilter from "@/views/apps/discounts/discountsChanges/discountsChangesFilter.vue";
import discountsChangesList from "@/views/apps/discounts/discountsChanges/discountsChangesList.vue";

import router from "@/router";

import { onBeforeMount } from "@vue/composition-api";
import { useBusinessSettings } from "@/composition/businessSettings/useBusinessSettings";

export default {
  components: {
    discountsProcessingFilter,
    discountsChangesList,
  },
  setup() {
    const { isEndUser } = useBusinessSettings();

    onBeforeMount(() => {
      if (isEndUser.value) {
        router.push({name: 'error-404'})
      }
    })
  }
}
</script>