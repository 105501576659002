import store from "@/store";
import { computed } from "@vue/composition-api";
import {useStoreModule} from "@/composition/useStoreModule";
import discountStoreModule from "@/store/discounts/discountStoreModule";

const STORE_MODULE_NAME = 'app-discounts';
let fetchParams = {};
function replacer(key, value) {
    if (value === null) {
        return undefined;
    }
    return value;
}

export function useDiscountChangesFetch() {

    const fetchDiscountChanges = async (params) => {
        fetchParams = {...fetchParams, ...params};
        fetchParams = JSON.parse(JSON.stringify(fetchParams, replacer));
        await store.dispatch(`${STORE_MODULE_NAME}/fetchDiscountChanges`, fetchParams);
    };

    const discountChanges = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DISCOUNT_CHANGES`];
    });

    const discountChangesPagination = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DISCOUNT_PAGINATION`];
    });

    const loadingDiscountChanges = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DISCOUNT_CHANGES_LOADING`];
    })

    useStoreModule(STORE_MODULE_NAME, discountStoreModule);

    return { discountChanges, fetchDiscountChanges, discountChangesPagination, loadingDiscountChanges };
}