<template>
  <b-card no-body>
    <b-skeleton-wrapper :loading="loadingDiscountChanges">
      <template #loading>
        <b-skeleton-table
            :rows="10"
            :columns="7"
            :table-props="{ bordered: true }"
        ></b-skeleton-table>
      </template>
      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="discountChanges"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Відповідних записів не знайдено"
      >
        <template #cell(title)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ getDiscountTemplatesTitle(data.item.templateId) || "Шаблон відсутній" }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.createdAt }}
          </div>
        </template>

        <template #cell(manager)="data">
          <b-link
              :to="{ name: 'apps-users-edit', params: { id: data.item.profileId } }"
          >
            <div class="font-weight-bold d-block text-nowrap">
              {{ managersByProfileId[data.item.profileId] && managersByProfileId[data.item.profileId].fullName }}
            </div>
          </b-link>
        </template>

        <template #cell(accountType)="data">
          <div
              v-for="account in data.item.typeAccountIds"
              :key="account"
              class="font-weight-bold d-block text-nowrap">
            {{ getAccountsTypesTitle(account) || "-" }}
          </div>
        </template>

        <template #cell(scheduledDate)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.scheduledAt || "-" }}
          </div>
        </template>
        <template #cell(status)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ getTasksStatusesTitle(data.item.statusId) || "-" }}
          </div>
        </template>
        <template #cell(targets)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ getTargetTypesTitle(data.item.targetTypeId) || "-" }}
          </div>
          <b-button :id="data.item.keyId" variant="primary" tabindex="0" size="sm">Детальніше</b-button>
          <b-popover :target="data.item.keyId" variant="primary" triggers="focus">
            <template #title>ID призначення</template>
            <p v-if="data.item.bundleIds">ID груп: {{ data.item.bundleIds.toString() }}</p>
            <p v-if="data.item.excludedBundleIds">ID виключених груп: {{ data.item.excludedBundleIds.toString() }}</p>
            <p v-if="data.item.profileIds">ID водіїв: {{ data.item.profileIds.toString() }}</p>
            <p v-if="data.item.excludedProfileIds">ID виключених водіїв: {{ data.item.excludedProfileIds.toString() }}</p>
            <p v-if="data.item.targetTypeId == 1">Знижка компанії</p>
            <p v-if="data.item.targetTypeId == 0">Знижка видалена</p>
          </b-popover>
        </template>
      </b-table>
    </b-skeleton-wrapper>
    <discountsChangesPagination />
  </b-card>
</template>

<script>

import { BTable, BCard, BButton, BPopover, BLink, BSkeletonTable, BSkeletonWrapper } from "bootstrap-vue";
import discountsChangesPagination from "@/views/apps/discounts/discountsChanges/discountsChangesPagination.vue";

import {computed, onBeforeMount} from "@vue/composition-api";
import { useDiscountChangesFetch } from "@/composition/discounts/useDiscountChangesFetch";
import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import { useTasksStatuses } from "@/composition/formOptions/useTasksStatuses";
import { useDiscountTargetTypes } from "@/composition/formOptions/useDiscountTargetTypes";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import store from '@/store'

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BPopover,
    BLink,
    BSkeletonTable,
    BSkeletonWrapper,
    discountsChangesPagination,
  },
  setup() {

    const tableColumns = [
      { key: 'createdAt', label: "Дата створення", sortable: false },
      { key: 'manager', label: "Менеджер", sortable: false },
      { key: 'title', label: "Шаблон", sortable: false },
      { key: 'accountType', label: "Тип рахунку", sortable: false },
      { key: 'scheduledDate', label: "Запланована дата", sortable: false },
      { key: 'status', label: "Статус", sortable: false },
      { key: 'targets', label: "застосування", sortable: false },
    ];

    const { discountChanges, fetchDiscountChanges, loadingDiscountChanges } = useDiscountChangesFetch();
    const { getDiscountTemplatesTitle, fetchDiscountTemplates } = useDiscountTemplatesFetch();
    const { getAccountsTypesTitle } = useAccountsTypes();
    const { getTasksStatusesTitle, fetchTasksStatuses } = useTasksStatuses();
    const { getTargetTypesTitle } = useDiscountTargetTypes();

    onBeforeMount(() => {
      fetchDiscountChanges({size: 10});
      fetchTasksStatuses();
      fetchDiscountTemplates();
    });

    const managersByProfileId = computed(() => store.getters['managersStoreModule/GET_MANAGERS_BY_PROFILE_ID'])

    return {
      discountChanges,
      tableColumns,
      managersByProfileId,
      loadingDiscountChanges,

      getDiscountTemplatesTitle,
      getTasksStatusesTitle,
      getTargetTypesTitle,
      getAccountsTypesTitle,
    };
  },
};
</script>