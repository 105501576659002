<template>
  <div class="mx-2 mb-2">
    <b-row>

      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <b-pagination
            v-if="discountChangesPagination && discountChangesPagination.total > 1"
            v-model="discountChangesPagination.page"
            :total-rows="discountChangesPagination.totalItems"
            :per-page="discountChangesPagination.size"
            @change="handlePagination($event)"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BPagination } from "bootstrap-vue";

import { useDiscountChangesFetch } from "@/composition/discounts/useDiscountChangesFetch";

export default {

  components: {
    BRow,
    BCol,
    BPagination,
  },

  setup() {
   const { discountChangesPagination, fetchDiscountChanges } = useDiscountChangesFetch();

   const handlePagination = (page) => {
     const params = {
       page: page,
       size: discountChangesPagination.value.size,
     };
     fetchDiscountChanges(params);
   };

   return { discountChangesPagination, handlePagination };
  },
};
</script>